<template>
  <div class="relative overflow-x-auto bg-white">
    <div v-if="props.loader">
      <TableSkeletonLoader :loader="props.loader" :cols="7" :rows="4" />
    </div>
    <div v-else>
      <table v-if="tableData?.length > 0" class="w-full text-sm text-left">
        <thead class="text-sm text-white bg-teal border-b">
          <tr>
            <th
              scope="col"
              class="px-6 py-4 tracking-wider font-medium"
              v-for="header in props.tableHeadings"
              :key="header"
            >
              {{ header }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr class="border-b" v-for="data in tableData" :key="data">
            <th class="px-6 py-4 font-medium text-gray-900 truncate max-clamp">
              {{ data?.agenda }}
            </th>
            <td
              v-if="isHQ != 'manager' && data?.sub_indicator"
              class="px-6 py-4 truncate max-clamp"
            >
              {{ data?.sub_indicator }}
            </td>
            <td v-if="isHQ != 'manager'" class="px-6 py-4">
              {{ data?.practice?.practice_name }}
            </td>
            <td class="px-6 py-4">
              {{ moment(data?.created_at).format("MMM DD YYYY") }}
            </td>
            <td class="px-6 py-4 text-left">
              0{{ data?.action_plan_meetings_count }}/04
            </td>
            <td v-if="data?.action_plan_meetings[0]?.date" class="px-6 py-4">
              {{
                moment(data?.action_plan_meetings[0]?.date).format(
                  "MMM DD YYYY"
                )
              }}
            </td>
            <td v-else class="px-6 py-4">N/A</td>
            <td class="px-6 py-4">
              <div
                class="status align-middle flex justify-center items-center w-max rounded-lg h-6 p-2"
                :class="{
                  'bg-yellow-50 text-yellow': data?.status === 'Feedback',
                  'bg-red-50 text-red': data?.status === 'Overdue',
                  'bg-green-50 text-green': data?.status === 'completed',
                  'bg-blue-50 text-blue': data?.status === 'in-progress',
                }"
              >
                <span
                  class="text-md capitalize"
                  :class="{
                    'text-yellow-400': data?.status === 'Feedback',
                    'text-red': data?.status === 'Overdue',
                    'text-green': data?.status === 'completed',
                    'text-blue': data?.status === 'in-progress',
                  }"
                  >{{ data?.status }}</span
                >
              </div>
            </td>
            <td class="px-6 py-4">
              {{ moment(data?.time_frame).format("MMM DD YYYY") }}
            </td>
            <td>
              <div
                class="flex items-center px-6 py-4 text-teal font-semibold opacity-70 cursor-pointer"
                @click="
                  actionPlansDetailPage(
                    data?.id,
                    data?.tag === 'qof' ? 'true' : 'false',
                    data?.survey_question?.patient_survey
                  )
                "
              >
                Open
                <div class="material-icons text-lg ml-1">launch</div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>

      <div v-else>
        <div
          class="flex flex-col items-center justify-center text-center w-full py-10"
        >
          <div class="rounded-full p-12 icon-bg">
            <img src="../../../assets/icons/noData.svg" class="w-32" />
          </div>
          <div class="mt-4 text-teal font-medium flex align-middle">
            No Data Found.
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { defineProps, toRefs, ref } from "vue";
import moment from "moment";
import TableSkeletonLoader from "@/components/newUi/atoms/TableSkeletonLoader.vue";
import { useRouter } from "vue-router";
const router = useRouter();
const props = defineProps({
  tableData: {
    type: Array,
    required: true,
  },
  tableTitle: {
    type: String,
    required: true,
  },
  tableHeadings: {
    type: Array,
    required: true,
  },
  routeName: {
    type: String,
    required: true,
  },
  loader: {
    type: Boolean,
    required: true,
  },
});

const { tableData } = toRefs(props);
const isHQ = ref(localStorage.getItem("role"));

const actionPlansDetailPage = (id, tag, surveyId) => {
  router.push({
    name: props.routeName,
    params: { actionPlanId: id, qof: tag, surveyId: surveyId ? surveyId : 0 },
  });
};
</script>

<style scoped>
.max-clamp {
  max-width: 150px;
}
.icon-bg {
  background: rgba(235, 248, 247, 1);
}
</style>
