<template>
  <div>
    <div class="flex flex-row items-center py-1 lg:px-0 sm:px-6 px-6">
      <div class="justify-items-start p-4 text-lg w-full">
        <p v-if="detailPage">Questions</p>
        <p v-else>Questions</p>
      </div>
      <div class="flex space-x-4 justify-end p-4 w-2/3 z-10">
        <v-select
          v-model="sortBy"
          class="w-3/4 cursor-pointer"
          label="Sort By"
          placeholder="Sort By"
          :searchable="false"
          :options="sortingList"
          @input="updateSorting"
        >
        </v-select>
        <v-select
          v-if="sortBy"
          v-model="sortOrder"
          class="w-3/4 cursor-pointer"
          label="label"
          placeholder="Sort Order"
          :searchable="false"
          :options="sortBy == 'Percentage' ? percentageOptions : averageOptions"
        >
        </v-select>
      </div>
    </div>
    <div class="relative overflow-x-auto bg-white">
      <div v-if="props.loader">
        <TableSkeletonLoader :loader="props.loader" :cols="3" :rows="4" />
      </div>
      <div v-else>
        <table
          v-if="localTableData?.length > 0"
          class="w-full text-sm text-left"
        >
          <thead class="text-sm text-white bg-teal border-b">
            <tr>
              <th
                scope="col"
                class="px-6 py-4 tracking-wider"
                v-for="header in props.tableHeadings"
                :key="header"
              >
                {{ header }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr class="border-b" v-for="data in localTableData" :key="data">
              <td
                class="px-6 py-4 w-2/3 font-medium text-gray-900 overflow-hidden"
              >
                <span class="py-2">{{
                  detailPage
                    ? data?.practice_name
                      ? data?.practice_name
                      : data?.question
                    : data?.question
                }}</span>
                <div class="w-full bg-gray-200 rounded-full h-2.5 mb-4">
                  <div
                    class="h-2.5 rounded-full"
                    :style="progressBarStyle(data?.percentage)"
                  ></div>
                </div>
              </td>

              <td class="px-6 py-4">
                {{ data?.percentage ? data?.percentage : 0 }}%
              </td>
              <td class="px-6 py-4">
                {{ data?.average ? data?.average : 0 }} / 5
              </td>
              <td>
                <div
                  v-if="!props?.detailPage"
                  @click="openQuestionsDetail(data?.id)"
                  class="flex items-center px-6 text-teal font-semibold opacity-70 cursor-pointer"
                >
                  Open<i class="material-icons text-lg ml-1">launch</i>
                </div>
                <div v-else>
                  <button
                    class="py-2 px-5 border hover:text-white hover:border-transparent rounded"
                    :style="actionButtonStyle(data?.percentage)"
                    :class="
                      data?.percentage >= 80
                        ? 'hover:bg-teal'
                        : data?.percentage >= 50
                        ? 'hover:bg-orange'
                        : 'hover:bg-red-500'
                    "
                    @click="routeToActionPlan(data?.id)"
                  >
                    Take Action
                  </button>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        <div v-else>
          <div
            class="flex flex-col items-center justify-center text-center w-full py-10"
          >
            <div class="rounded-full p-12 icon-bg">
              <img src="../../../assets/icons/noData.svg" class="w-32" />
            </div>
            <div class="mt-4 text-teal font-medium flex align-middle">
              No Data Found.
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { defineProps, toRefs, ref, watchEffect } from "vue";
import { useRouter, useRoute } from "vue-router";

import TableSkeletonLoader from "@/components/newUi/atoms/TableSkeletonLoader.vue";

const router = useRouter();
const route = useRoute();

const sortingList = ref(["Percentage", "Average"]);
const sortBy = ref(null);
const percentageOptions = ref(["Highest %age", "Lowest %age"]);
const averageOptions = ref(["Highest Average", "Lowest Average"]);
const sortOrder = ref("");
const previousSortBy = ref(null);

const props = defineProps({
  tableData: {
    type: Array,
    required: true,
  },
  tableTitle: {
    type: String,
    required: true,
  },
  tableHeadings: {
    type: Array,
    required: true,
  },
  detailPage: {
    type: Boolean,
    default: false,
  },
  actionPlanPageRoute: {
    type: String,
    required: true,
  },
  loader: {
    type: Boolean,
    required: true,
  },
});

const { tableData } = toRefs(props);
const localTableData = ref(tableData.value);
const isManager = ref(localStorage.getItem("role"));

watchEffect(() => {
  if (sortBy.value !== previousSortBy.value) {
    sortOrder.value = null;
    previousSortBy.value = sortBy.value;
  }

  if (sortBy.value && sortOrder) {
    const sortedData = localTableData.value.sort((a, b) => {
      const aValue = sortBy.value === "Percentage" ? a.percentage : a.average;
      const bValue = sortBy.value === "Percentage" ? b.percentage : b.average;

      if (
        sortOrder.value === "Highest %age" ||
        sortOrder.value === "Highest Average"
      ) {
        return bValue - aValue;
      } else {
        return aValue - bValue;
      }
    });

    localTableData.value = sortedData;
  }
});

const progressBarStyle = (value) => {
  if (value > 0) {
    return {
      width: `${value}%`,
      "background-color":
        value >= 80 ? "#00A499" : value >= 50 ? "#EEA23E" : "#E2341D",
    };
  } else {
    return {
      width: `${value}%`,
      "background-color": "e5e7eb",
    };
  }
};

const actionButtonStyle = (value) => {
  return {
    "border-color":
      value >= 80 ? "#00A499" : value >= 50 ? "#EEA23E" : "#E2341D",
    color: value >= 80 ? "#00A499" : value >= 50 ? "#EEA23E" : "#E2341D",
    "background-color-color":
      value >= 80 ? "#00A499" : value >= 50 ? "#EEA23E" : "#E2341D",
  };
};

const routeToActionPlan = (id) => {
  if (isManager.value === "manager") {
    router.push({
      name: props.actionPlanPageRoute,
      params: { questionId: id },
    });
  } else {
    router.push({
      name: props.actionPlanPageRoute,
      params: { questionId: route.params.id, practiceId: id },
    });
  }
};

const openQuestionsDetail = (id) => {
  router.push({ name: "questions-detail-page", params: { id: id } });
};

const updateSorting = (value) => {
  sortBy.value = value;
};
</script>

<style scoped>
.icon-bg {
  background: rgba(235, 248, 247, 1);
}
</style>
