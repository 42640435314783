<template>
  <div class="flex items-center justify-center py-6 lg:px-0 sm:px-6 px-6">
    <div class="w-full flex items-center justify-between">
      <button
        class="flex items-center text-gray-600 hover:text-teal cursor-pointer"
        :class="{ 'pointer-events-none opacity-50': currentPage === 1 }"
        @click="prevPage(currentPage)"
      >
        <span class="material-icons text-sm ml-4">arrow_back</span>
        <span class="text-sm font-medium leading-none px-1">Prev</span>
      </button>
      <div class="flex items-center">
        <button
          v-for="page in props.totalPages"
          :key="page"
          class="mx-6 text-sm font-medium cursor-pointer text-gray-600 hover:text-teal"
        >
          <span @click="selectPage(page)" :class="{ 'text-teal': currentPage === page }">{{ page }}</span>
        </button>
      </div>
      <button
        class="flex items-center text-gray-600 hover:text-teal cursor-pointer"
        :class="{ 'pointer-events-none opacity-50': currentPage === totalPages }"
        @click="nextPage(currentPage)"
      >
        <span class="text-sm font-medium leading-none px-1">Next</span>
        <span class="material-icons text-sm mr-4">arrow_forward</span>
      </button>
    </div>
  </div>
</template>

<script setup>
import { defineProps, defineEmits } from "vue";

const emits = defineEmits(['page-changed'])

const nextPage = (page) => {
  emits('page-changed', page + 1);
}

const prevPage = (page) => {
  emits('page-changed', page - 1);
}

const selectPage = (page) => {
  emits('page-changed', page);
}

const props = defineProps({
  currentPage: Number,
  totalPages: Number
});
</script>