<template>
  <div class="border-2 border-gray-100 bg-white rounded-md">
    <p class="text-black font-medium py-5 px-7">Current Survey</p>
    <div>
      <div
        v-for="(survey, idx) in activeSurveys"
        :key="idx"
        class="flex flex-row justify-between bg-teal bg-opacity-10 px-6"
      >
        <div v-if="activeSurveys" class="flex flex-col gap-4 py-10">
          <div class="flex">
            <span class="material-icons text-red mt-2 ml-2 text-4xl"
              >insert_drive_file</span
            >
            <div class="ml-4">
              <p class="font-medium">
                Patient Survey {{ survey?.month }}, {{ survey?.year }}
              </p>
              <p class="text-textGray">
                {{ survey?.month }}
                {{ moment(survey?.created_at, "YYYY/MM/DD").date() }},
                {{ survey?.year }}
              </p>
            </div>
          </div>
        </div>
        <div class="grid content-center">
          <button
            class="flex items-center w-1/7 px-6 py-4 text-teal font-semibold opacity-80 cursor-pointer"
            @click="redirectToLinks(survey, 1)"
          >
            Open<span class="material-icons text-lg ml-1">launch</span>
          </button>
        </div>
      </div>
      <div class="h-96 flex flex-col">
        <p class="text-black font-medium py-5 px-7 border-b">
          Archived Surveys
        </p>
        <div v-if="archivedSurveys.length > 0" class="mt-2 border-b py-3 px-7">
          <div
            class="flex justify-between"
            v-for="(survey, idx) in archivedSurveys"
            :key="idx"
          >
            <div class="flex">
              <span class="material-icons text-red mt-2 ml-2 text-4xl"
                >insert_drive_file</span
              >
              <div class="ml-4">
                <p class="font-medium">Patient Survey</p>
                <p class="text-textGray">
                  {{ survey?.month }}, {{ survey?.year }}
                </p>
              </div>
            </div>
            <div>
              <button
                class="flex items-center w-1/7 px-6 py-4 text-teal font-semibold opacity-80 cursor-pointer"
                @click="redirectToLinks(survey, 0)"
              >
                Open<span class="material-icons text-lg ml-1">launch</span>
              </button>
            </div>
          </div>
        </div>
        <div v-else class="m-auto text-xl">No previous surveys</div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import moment from "moment";

const store = useStore();
const router = useRouter();
const isManager = localStorage.getItem("role");

const activeSurveys = computed(() => {
  if (isManager === "manager") {
    return store.getters["MAPatientSurveys/getActiveSurveys"];
  } else {
    return store.getters["patientSurveys/getActiveSurveys"];
  }
});

const archivedSurveys = computed(() => {
  if (isManager === "manager") {
    return store.getters["MAPatientSurveys/getArchivedSurveys"];
  } else {
    return store.getters["patientSurveys/getArchivedSurveys"];
  }
});

const redirectToLinks = (survey, isActive) => {
  if (isManager === "manager") {
    router.push({
      name: "ma-survey-entries",
      params: { id: survey.id, isActive: isActive },
    });
  } else {
    router.push({
      name: "hq-survey-entries",
      params: { id: survey.id, isActive: isActive },
    });
  }
};
</script>
