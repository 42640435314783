<template>
  <div :key="props.order">
    <canvas id="bar-chart" class="w-full p-6 h-96"></canvas>
  </div>
</template>

<script setup>
import Chart from "chart.js";
import { onMounted, ref, defineProps } from "vue";

const props = defineProps({
  data: {
    type: Array,
    required: true,
  },
  order: {
    type: String,
    required: false,
    default: "asc",
  }
});

const chartData = ref(props.data);

const config = {
  type: "bar",
  data: {
    labels: chartData.value?.map((question, index) => {
      return `Q${index + 1}`;
    }),
    datasets: [
      {
        label: "Strongly Disagree",
        data: chartData.value?.map((i) => {
          return i.strongly_disagree;
        }),
        backgroundColor: "#E2341D",
        stacked: "stack",
        barPercentage: 0.5,
      },
      {
        label: "Disagree",
        data: chartData.value?.map((i) => {
          return i.disagree;
        }),
        backgroundColor: "#EEA23E",
        stacked: "stack",
        barPercentage: 0.5,
      },
      {
        label: "Neutral",
        data: chartData.value?.map((i) => {
          return i.neutral;
        }),
        backgroundColor: "#5CB1FF",
        stacked: "stack",
        barPercentage: 0.5,
      },
      {
        label: "Agree",
        data: chartData.value?.map((i) => {
          return i.agree;
        }),
        backgroundColor: "#00A499",
        stacked: "stack",
        barPercentage: 0.5,
      },
      {
        label: "Strongly Agree",
        data: chartData.value?.map((i) => {
          return i.strongly_agree;
        }),
        backgroundColor: "#135DCB",
        stacked: "stack",
        barPercentage: 0.5,
      },
    ],
  },
  options: {
    scales: {
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
          },
        },
      ],
      xAxes: [
        {
          ticks: {
            reverse: props.order === "desc" ? true : false,
          },
        },
      ],
    },
    aspectRatio: 14 / 3,
    legend: {
      display: true,
      position: "bottom",
      align: "start",
    },
    tooltips: {
      callbacks: {
        label: function (tooltipItem) {
          return tooltipLabel(tooltipItem);
        },
        title: function (tooltipItem, data) {
          return tooltipTitle(tooltipItem, data);
        },
      },
    },
  },
};

const chartObj = ref(null);
const chartConfig = ref(config);

const tooltipLabel = (tooltipItem) => {
  const index = tooltipItem.index;
  return "Question: " + chartData.value[index]?.question;
};
const tooltipTitle = (tooltipItem, data) => {
  return `${data.datasets[tooltipItem[0].datasetIndex].label}: ` + tooltipItem[0].value;
};

onMounted(() => {
  chartObj.value = new Chart(
    document.getElementById("bar-chart"),
    chartConfig.value
  );
});
</script>
